import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Volumes/casesens/Users/matteoricci/Work/MaterialSolutions/Blog/materialx-blog/node_modules/gatsby-theme-catalyst-core/src/components/layout.js";
import { SEO } from "gatsby-theme-catalyst-core";
import Hero from "../../src/components/hero";
import Company from "../../src/components/company";
import Work from "../../src/components/case-studies";
import Publications from "../../src/components/publications";
import Team from "../../src/components/team";
import Contact from "../../src/components/contact";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Hello and Welcome" mdxType="SEO" />
    <Hero mdxType="Hero" />
    <Company mdxType="Company" />
    <Work mdxType="Work" />
    <Publications mdxType="Publications" />
    <Team mdxType="Team" />
    <Contact mdxType="Contact" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      